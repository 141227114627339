import React from "react";
import { logo } from "../../../assests/images/images";
import { Default } from "../../../assests/images/images";
import Header from "../../common/header/header";
import FooterContent from "../../common/footer/footer";
import { withTranslation } from "react-i18next";
import "./ViewUsers.css";
import useViewUsersHook from "./useViewUsersHook";
import { ViewUserService } from "../../../services/ViewUserService";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../assests/images/images";
import { format } from "date-fns";
import moment from "moment";

function ViewUsers(props) {
  const { t } = props;
  const {
    image_url,
    data,
    navigate,
    handleImageClick,
    setModalOpen,
    selectedImage,
    videoFile,
    modalOpen,
    loader,
    PageHandler,
    setStateHandler,
    changeStatus,
  } = useViewUsersHook(t);
  console.log(data);

  const ViewUserContent = () => {
    return (
      <>
        <div className="viewNewsUsers_container flt">
          {!loader && (
            <div className="load flt">
              <img src={Loading} alt="Stylish" />
            </div>
          )}
          <div className="page_head flt mb-4">{t("User_Details")}</div>
          <div className="back_button1 flt">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {" "}
              <i class="fas fa-arrow-left"></i> {t("Back")}
            </button>
          </div>

          {data && data.data && (
            <div className="viewNews_cont flt">
              <div className="row mb-4">
                <div className="col-sm-8">
                  <div className="styuser_list flt">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="stylimg flt">
                          {data.data.profile_photo ? (
                            <img src={image_url + data.data.profile_photo} />
                          ) : (
                            <img src={Default} />
                          )}
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <ul className="styldetails2 flt">
                          {/* <li className='styhead flt'>STYLIST USER DETAILS</li> */}

                          <li>
                            <b>{t("Name")} :</b> {data.data.name}
                          </li>
                          <li>
                            <b>{t("User_Type")} :</b> {data.data.user_type}
                          </li>
                          <li>
                            <b>{t("Email")} :</b> {data.data.email}
                          </li>
                          <li>
                            <b>{t("Gender")} :</b> {data.data.gender}
                          </li>
                          <li>
                            <b> {t("DOB")} :</b>{" "}
                            {moment(data?.data?.date_of_birth).format(
                              "DD/MM/yyyy"
                            )}
                          </li>
                          <li>
                            <b>{t("Address")} :</b> {data.data.address}
                          </li>
                          <li>
                            <b> {t("Prefecture")} :</b> {data.data.prefecture}
                          </li>
                          <li>
                            <b> {t("Display_Name")} :</b>{" "}
                            {data.data.display_name}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="underline flt">
                      <ul className="styldetails2 flt">
                        <li>
                          <b> {t("Favourite_Fashion")}: </b>
                          {data.data.fav_fashion.map((el, index) => {
                            return (
                              <span key={el.id}>
                                {(index ? "," : "") + el.name}
                              </span>
                            );
                          })}
                        </li>

                        <li>
                          {" "}
                          <b>{t("Fav_Brand")}: </b>
                          {data.data.fav_brand.map((el, index) => {
                            return (
                              <span
                                key={el.id}
                                dangerouslySetInnerHTML={{
                                  __html: (index ? "," : "") + el.name,
                                }}
                              ></span>
                            );
                          })}
                        </li>
                        <li>
                          <b>{t("Bid_Point")} : </b>
                          {data.data.bid_point}
                        </li>
                        <li className="text2 flt">
                          <b> {t("Bio")} : </b> {data.data.bio}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="styuser_list flt">
                    <ul className="styldetails2 flt">
                      <li className="styhead flt">{t("Bank_Details")}</li>
                      <li>
                        {" "}
                        <b>{t("First_Name")}: </b>
                        {data.data.bank_details[0].first_name}
                      </li>
                      <li>
                        {" "}
                        <b>{t("Sur_Name")}: </b>
                        {data.data.bank_details[0].sur_name}
                      </li>
                      <li>
                        {" "}
                        <b>{t("Bank_Name")}: </b>
                        {data.data.bank_details[0].bank_name}
                      </li>
                      <li>
                        <b>{t("Branch_Name")}: </b>
                        {data.data.bank_details[0].brnach_name}
                      </li>
                      <li>
                        <b>{t("Account_Details")}: </b>
                        {data.data.bank_details[0].account_type}
                      </li>
                      <li>
                        <b>{t("Account_Number")}: </b>
                        {data.data.bank_details[0].account_number}
                      </li>
                    </ul>
                  </div>

                  <div className="styuser_list1 flt">
                    <ul className="styldetails2 flt">
                      <li className="promvideo flt">
                        <b>{t("Promo_Video")}: </b>
                        {data.data.promotion_video ? (
                          <video
                            className="video_display"
                            src={image_url + data.data.promotion_video}
                            type="video/mp4"
                            controls
                          ></video>
                        ) : (
                          ""
                        )}
                      </li>

                      <li>
                        {" "}
                        <b> {t("Insta_Wrinkle")}:</b>{" "}
                        {data.data.instagram_wrinkle}
                      </li>
                      <div>
                        <b> {t("Willingness_Propose")} :</b>{" "}
                        <span>{data.data.willingness_propose}</span>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="styuser_list flt">
                <div className="styhead flt">身分証投稿</div>
                {/* <div className=" row">
                  {data.data.id_post.map((postId) => (
                    <div key={postId} className="col-sm-4">
                      <img
                        src={`${image_url}${postId}`}
                        alt={`Image ${postId}`}
                      />
                    </div>
                  ))}
                </div> */}
                <div className="grid-container flt">
                  {data.data.id_post.map((image) => (
                    <img
                      src={`${image_url}${image}`}
                      className="grid-item"
                      onClick={() => handleImageClick(`${image_url}${image}`)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  };

  const FullImage = () => {
    return (
      //   <div className="modalPop" id="enquiryModal">
      //     <div className="modal-dialog">
      //       <div className="modal-content">
      //         <div className="modal-body">
      //           <button type="button" className="close">
      //             gsdfgfdg
      //             <img alt="no_image" />
      //           </button>
      //           {/* <div className="enquiry_head flt">{t("verify_otp")}</div> */}
      //           <div className="enquiry_cont flt">
      //             gsfdgsdfg
      //             <img className="example" alt="noimage" />
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      <div class="modal_body_wrapper">
        <div class="modal_backdrop" onClick={() => setModalOpen(false)}></div>
        <div class="modal_body">
          <div class="modal_head">
            <div></div>
            <i
              class="fa-solid fa-xmark"
              onClick={() => setModalOpen(false)}
            ></i>
          </div>
          <div className="Modal_body">
            <img src={selectedImage} />
          </div>
          <div class="modal_content"></div>
          <div class="modal_footer"></div>
        </div>
      </div>
    );
  };
  return (
    <>
      {Header()}
      <div className="page_container flt">{ViewUserContent()}</div>
      {modalOpen && FullImage()}
      {FooterContent()}
    </>
  );
}

export default withTranslation()(ViewUsers);
